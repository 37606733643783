import dispatchToast from 'components/modules/Toast';
import { postMembershipIssueCard } from 'features/membership/api';
import cuminFormatDataForApi from 'features/membership/libs/cuminFormatDataForApi';
import { CuminIssueCardFormInput } from 'features/membership/types/form';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useSetRecoilState } from 'recoil';

const useCardIssueConfirm = (
  memberFormData?: Partial<CuminIssueCardFormInput>
) => {
  const navigate = useNavigate();
  const setMembershipMemberResponse = useSetRecoilState(
    membershipMemberResponseAtom
  );
  const [{ loading: isMutating }, onIssueButtonClick] = useAsyncFn(async () => {
    if (typeof memberFormData === 'undefined') return;

    const res = await postMembershipIssueCard(
      cuminFormatDataForApi(memberFormData)
    );

    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      setMembershipMemberResponse(undefined); // MBS-API003 会員情報照会API のレスポンス のグローバルステートを削除
      navigate('/membership/cumin/issue/confirm', { replace: true }); // state を削除
      navigate('/membership/cumin/issue/complete'); // 完了ページへ
      return;
    }
    // 個別な項目じゃない問題で引っかかった場合
    if (!(res.error_fields && Object.keys(res.error_fields).length)) {
      dispatchToast({
        id: 'membership-member-api-error',
        toastText: res.errors.join('\n')
      });
      return;
    }

    if (res.error_fields && Object.keys(res.error_fields).length) {
      navigate('/membership/cumin/issue', {
        state: { formData: memberFormData, errors: res.error_fields }
      });
      return;
    }

    dispatchToast({
      id: 'membership-member-api-error',
      toastText:
        'エラーが発生しております。暫く経ってからもう一度お試しください'
    });
  }, [memberFormData, navigate]);

  return {
    onIssueButtonClick,
    isMutating
  };
};
export default useCardIssueConfirm;
