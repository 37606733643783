import { Button, Image, Stack, Text, VStack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import LinkText from 'components/modules/LinkText';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type CardLinkCompletePageProps = {
  title: string;
  explanation: string;
  imageUrl: string;
};

/**
 * Cumin完了コンポーネント
 */
const CuminCompletePage: FC<CardLinkCompletePageProps> = ({
  title,
  explanation,
  imageUrl
}) => {
  return (
    <MainLayout header>
      <Stack
        justifyContent="space-between"
        px="1rem"
        pt="3rem"
        pb="0.75rem"
        minH="100%"
        gap="1rem"
      >
        <VStack gap="1rem" w="100%" alignItems="flex-start">
          {/* タイトル */}
          {title && (
            <Text
              fontSize="xl"
              fontWeight="700"
              wordBreak="break-all"
              whiteSpace="pre-wrap"
            >
              {title}
            </Text>
          )}
          {/* 画像 */}
          {imageUrl && (
            <Image
              alignSelf="center"
              src={imageUrl}
              width="100%"
              objectFit="contain"
              alt="完了画像"
              borderRadius="10px"
            />
          )}
          {/* 説明文 */}
          {explanation && (
            <LinkText
              variant="secondary" // NOTE: Cuminのリンクカラーは secondary
              content={explanation}
              fontSize="lg"
            />
          )}
        </VStack>

        <Button
          as={RouterLink}
          to="/membership/cumin"
          variant="primary-fullwidth-rounded"
          whiteSpace="pre-wrap"
          wordBreak="break-all"
        >
          会員証トップへ
        </Button>
      </Stack>
    </MainLayout>
  );
};

export default CuminCompletePage;
