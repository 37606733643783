import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  Stack,
  Text
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import TermOfService from 'components/modules/TermOfService';
import BarcodeScanBox from 'features/membership/components/BarcodeScanBox';
import { Inputs } from 'features/membership/hooks/useSimpleForm';
import { SimplePageTemplateOptionKey } from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { handleAnchorClick, openWindow } from 'libs/line';
import { FC, FormEventHandler, useState } from 'react';
import { FormState, UseFormRegister, UseFormSetValue } from 'react-hook-form';

type FormPageProps = {
  isSignUp?: boolean;
  useFormReturn: {
    register: UseFormRegister<Inputs>;
    setValue: UseFormSetValue<Inputs>;
    formState: FormState<Inputs>;
  };
  onFormSubmit: () => FormEventHandler<HTMLDivElement>;
  templateOptions?: Record<
    Extract<
      SimplePageTemplateOptionKey,
      | 'formTopImageOption'
      | 'signupFormTermsOfServiceContentOption'
      | 'formCardNumberLabelContentOption'
      | 'formCardNumberPlaceholderContentOption'
      | 'formCameraReadingOption'
      | 'formCameraReadingLabelContentOption'
      | 'formNoticeContentOption'
      | 'signupFormLinkOption'
      | 'editFormLinkOption'
    >,
    PageTemplateOption | undefined
  >;
  // ツルハで「カード番号」のラベルを変更したいとの要望があったため、一時対応としてラベルをカスタマイズできるようにしています
  cardNumberLabel?: string;
};

/**
 * フォームリンクボタンコンポーネント
 * 新規登録時かを確認しつつそれぞれのオプション内の値の存在チェックを元に
 * 適切なリンクを表示する
 */
const FormLink: FC<Pick<FormPageProps, 'templateOptions' | 'isSignUp'>> = ({
  isSignUp,
  templateOptions
}) => {
  const linkLabel = isSignUp
    ? templateOptions?.signupFormLinkOption?.link_label
    : templateOptions?.editFormLinkOption?.link_label;
  const linkUrl = isSignUp
    ? templateOptions?.signupFormLinkOption?.link_url
    : templateOptions?.editFormLinkOption?.link_url;

  // ラベルとURLのどちらかがない場合はリンクを表示しない
  if (!linkLabel || !linkUrl) return null;

  return (
    <Center>
      <Button
        type="button"
        variant="primary-link"
        size="xl"
        fontWeight="700"
        padding="0"
        onClick={linkUrl ? () => openWindow(linkUrl) : undefined}
      >
        {linkLabel}
      </Button>
    </Center>
  );
};

/**
 * 会員連携入力フォームページ
 * Simple, Pepper, Mintのテンプレートで利用する共通コンポーネント
 */
const SimpleFormPage: FC<FormPageProps> = ({
  isSignUp,
  useFormReturn: {
    register,
    setValue,
    formState: { isSubmitting, errors, isValid }
  },
  onFormSubmit,
  templateOptions,
  // ツルハで「カード番号」のラベルを変更したいとの要望があったため、一時対応としてラベルをカスタマイズできるようにしています
  cardNumberLabel = 'カード番号'
}) => {
  // 利用規約文言
  const termOfServiceContent =
    templateOptions?.signupFormTermsOfServiceContentOption?.content;
  // 利用規約テンプレートオプションが存在する かつ 新規登録の場合はtrue
  const showTerm = !!termOfServiceContent && isSignUp;
  // 利用規約表示がない場合は、初期値がtrueになる
  const [isChecked, setIsChecked] = useState<boolean>(!showTerm);

  return (
    <MainLayout header footer>
      <Container pt="3rem">
        <Stack spacing="2.5rem">
          {/* トップ画像 */}
          {templateOptions?.formTopImageOption?.image_url && (
            <Box mx="-1rem">
              <Image
                width="100%"
                maxHeight="20rem"
                objectFit="contain"
                src={templateOptions?.formTopImageOption.image_url}
                alt="トップ"
              />
            </Box>
          )}
          {/* フォーム */}
          <Stack as="form" spacing="3rem" onSubmit={onFormSubmit()}>
            <Stack spacing="1.5rem">
              {/* 文言 */}
              {templateOptions?.formCardNumberLabelContentOption?.content && (
                <Text fontSize="sm" fontWeight="bold" textAlign="center">
                  {templateOptions.formCardNumberLabelContentOption.content}
                </Text>
              )}
              {/* カード番号入力欄 */}
              <FormControl isInvalid={!!errors.card_number}>
                <Input
                  type="text"
                  maxLength={255}
                  placeholder={
                    templateOptions?.formCardNumberPlaceholderContentOption
                      ?.content || ''
                  }
                  {...register('card_number')}
                />
                {errors.card_number && (
                  <FormErrorMessage>
                    {errors.card_number.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              {/* 利用規約チェックボックス 利用規約が存在する場合 かつ 新規登録時に表示*/}
              {showTerm && (
                <TermOfService
                  variant="primary"
                  content={termOfServiceContent}
                  onCheckboxChange={(e) => setIsChecked(e.target.checked)}
                  onLinkClick={handleAnchorClick}
                />
              )}

              {/* 「登録確認へ」ボタン */}
              <Button
                type="submit"
                variant="primary-fullwidth-rounded"
                isDisabled={!isValid || !isChecked}
                isLoading={isSubmitting}
              >
                登録確認へ
              </Button>
              {/* 区切り線 */}
              <Center>
                <Divider borderColor="dark.100" opacity={1} width="50%" />
              </Center>
              {/* カメラ読み取り */}
              {templateOptions?.formCameraReadingOption && (
                <>
                  {/* 文言 */}
                  {templateOptions?.formCameraReadingLabelContentOption
                    ?.content && (
                    <Text fontSize="sm" fontWeight="bold" textAlign="center">
                      {
                        templateOptions?.formCameraReadingLabelContentOption
                          .content
                      }
                    </Text>
                  )}
                  {/* カメラ読み取り動線 */}
                  <BarcodeScanBox
                    onDetected={(code) =>
                      setValue('card_number', code, { shouldValidate: true })
                    }
                    cardNumberLabel={cardNumberLabel}
                  />
                </>
              )}
            </Stack>
            <Stack gap="0.75rem">
              {/* 注意書き */}
              {templateOptions?.formNoticeContentOption?.content && (
                <Text fontWeight="400" fontSize="sm" whiteSpace="pre-line">
                  {templateOptions.formNoticeContentOption.content}
                </Text>
              )}
              {/* リンク */}
              <FormLink templateOptions={templateOptions} isSignUp={isSignUp} />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </MainLayout>
  );
};

export default SimpleFormPage;
