import dispatchToast from 'components/modules/Toast';
import { patchMembershipProfileEdit } from 'features/membership/api';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import cuminFormatDataForApi from 'features/membership/libs/cuminFormatDataForApi';
import cuminFormatDataForForm from 'features/membership/libs/cuminFormatDataForForm';
import {
  cuminMemberFormRequiredFieldsState,
  cuminMemberFormVisibleFieldsState
} from 'features/membership/libs/recoil/selector';
import {
  CuminIssueCardFormInput,
  CuminUserProfileFormInput
} from 'features/membership/types/form';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { membershipProfileResponseAtom } from 'libs/recoil/atom';
import { BaseSyntheticEvent, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useMemberForm } from './useMemberForm';

type UseProfileEditFormReturn = {
  isMutating: boolean;
  methods: UseFormReturn<CuminIssueCardFormInput | CuminUserProfileFormInput>;
  formSubmitHandler: (event?: BaseSyntheticEvent) => Promise<void>;
};

const useProfileEditForm = (): UseProfileEditFormReturn => {
  const navigate = useNavigate();
  /**
   * 会員情報
   */
  const membershipProfileResponse = useRecoilValue(
    membershipProfileResponseAtom
  );
  /**
   * Cumin template の中で表示する項目名一覧
   */
  const cuminMemberFormVisibleFields = useRecoilValue(
    cuminMemberFormVisibleFieldsState
  );
  /**
   * Cumin template の中で必須入力項目名一覧
   */
  const cuminProfileRequiredFields = useRecoilValue(
    cuminMemberFormRequiredFieldsState
  );
  /**
   * 会員情報保存
   */
  const resetMembershipProfileResponse = useResetRecoilState(
    membershipProfileResponseAtom
  );

  const { methods, setServerErrors } = useMemberForm({
    defaultValues: membershipProfileResponse
      ? cuminFormatDataForForm(membershipProfileResponse)
      : undefined,
    // 会員情報編集フォームの場合、生年月日とよく行く店舗は必須項目から除外
    formFields: cuminMemberFormVisibleFields?.filter(
      (fieldName) => !['birthday', 'entry_store_id'].includes(fieldName)
    ),
    requiredFormFields: cuminProfileRequiredFields?.filter(
      (fieldName) => !['birthday', 'entry_store_id'].includes(fieldName)
    )
  });

  const [{ loading: isMutating }, confirmFormData] = useAsyncFn<
    (formData: Partial<CuminUserProfileFormInput>) => Promise<void>
  >(async (formData) => {
    if (typeof formData === 'undefined') return undefined;

    const res = await patchMembershipProfileEdit(
      cuminFormatDataForApi(formData)
    );

    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      resetMembershipProfileResponse();

      dispatchToast({
        id: 'membership-member-api-success',
        toastText: '会員情報を保存しました'
      });

      navigate(`/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/profile`);
      return;
    }

    if (res.error_fields && Object.keys(res.error_fields).length) {
      setServerErrors(res.error_fields);
      return;
    }

    // 個別な項目じゃない問題で引っかかった場合
    dispatchToast({
      id: 'membership-member-api-error',
      toastText: res.errors?.join('\n')
    });
  }, []);

  const formSubmitHandler = methods.handleSubmit(
    async (formData) => await confirmFormData(formData)
  );

  useEffect(() => {
    if (membershipProfileResponse) {
      methods.reset(cuminFormatDataForForm(membershipProfileResponse));
    }
  }, [membershipProfileResponse, methods]);

  return {
    isMutating,
    methods,
    formSubmitHandler
  };
};

export default useProfileEditForm;
