import { TemplateAliasCode } from 'features/membership/types';
import { FEATURES } from 'libs/features';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type FeatureVerifierProps = {
  hasActiveFeatures: boolean;
  children?: ReactNode;
} & TemplateAliasCode;

/**
 * 有効なFeatureが存在するかをチェックするコンポーネント
 */
const FeatureVerifier: FC<FeatureVerifierProps> = ({
  hasActiveFeatures,
  children,
  templateAliasCode
}) => {
  if (!hasActiveFeatures) {
    return <Navigate to={`${FEATURES.membership.path}/${templateAliasCode}`} />;
  }

  return <>{children}</>;
};

export default FeatureVerifier;
