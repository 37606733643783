import MainLayout from 'components/layouts/MainLayout';
import { getMembershipSetting } from 'features/membership/api';
import { API_RESPONSE_STATUS, NOW_LOADING } from 'libs/constants';
import {
  membershipSettingAtom,
  tenantConfigurationAtom
} from 'libs/recoil/atom';
import { activeFeatureCodesState } from 'libs/recoil/selector';
import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

/**
 * 会員連携ルートコンポーネント
 *
 * - 会員連携機能が無効: トップへ飛ばす
 * - 会員連携機能が有効: 会員連携情報を取得し、完了したら子要素をレンダリング
 */
const MembershipRoot: FC = () => {
  const navigate = useNavigate();
  const tenantConfiguration = useRecoilValue(tenantConfigurationAtom);
  const activeFeatureCodes = useRecoilValue(activeFeatureCodesState);
  const [
    {
      page_template: { alias_code }
    },
    setMembershipSetting
  ] = useRecoilState(membershipSettingAtom);
  const resetMembershipSetting = useResetRecoilState(membershipSettingAtom);

  useEffect(() => {
    // テナント情報の取得完了まで待つ(code と name どちらも空文字なら未取得とみなす)
    if (!tenantConfiguration.code && !tenantConfiguration.name) return;

    // 会員連携機能が無効のときはトップへ
    if (!activeFeatureCodes.includes('membership')) {
      resetMembershipSetting(); // 初期値にリセット
      navigate('/');
      return;
    }

    // 会員連携情報をすでに取得済みの場合スキップ(alias_code が空文字なら未取得とみなす)
    if (alias_code) return;

    // 会員連携情報の取得
    getMembershipSetting().then((data) => {
      if (data.status == API_RESPONSE_STATUS.FAILED) return navigate('/'); // 失敗時はトップへ
      setMembershipSetting(data.data);
    });
  }, [
    navigate,
    tenantConfiguration,
    activeFeatureCodes,
    alias_code,
    setMembershipSetting,
    resetMembershipSetting
  ]);

  // 会員連携情報の取得完了まで待つ(alias_code が空文字なら未取得とみなす)
  if (!alias_code) return <MainLayout pageTitle={NOW_LOADING} />;

  return <Outlet />;
};

export default MembershipRoot;
