import MainLayout from 'components/layouts/MainLayout';
import { getMembershipMember } from 'features/membership/api';
import { TemplateAliasCode } from 'features/membership/types';
import { NOW_LOADING } from 'libs/constants';
import { FEATURES } from 'libs/features';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { FC, ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

type MemberVerifierProps = {
  /**
   * 会員連携の必要・不要
   *
   * 会員連携済みユーザーのみアクセスできるページの場合: true
   * 会員未連携ユーザーのみアクセスできるページの場合: false
   */
  isRequire?: boolean;
  children?: ReactNode;
} & TemplateAliasCode;

/**
 * 会員連携状況をチェックするコンポーネント
 *
 * 参考: https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
 */
const MemberVerifier: FC<MemberVerifierProps> = ({
  isRequire = true,
  children,
  templateAliasCode
}) => {
  const [membershipMemberResponse, setMembershipMemberResponse] =
    useRecoilState(membershipMemberResponseAtom);

  useEffect(() => {
    // WARNING: 定期的にデータをリフレッシュしたほうが良ければここで対応する(atom に追加で更新日時を記録して、◯◯分経過してたら再実行するみたいな？)
    // 未取得であれば会員情報照会APIを実行する
    if (!membershipMemberResponse)
      getMembershipMember().then((res) => setMembershipMemberResponse(res));
  }, [membershipMemberResponse, setMembershipMemberResponse]);

  // ロード中
  if (!membershipMemberResponse) return <MainLayout pageTitle={NOW_LOADING} />;

  // 会員連携済みユーザーのみアクセスできるページの場合: 会員未連携ユーザーは、テンプレートに応じた会員登録トップへ ※ 404 のみ返ってくる想定なので status のみで判断してる
  if (isRequire && membershipMemberResponse.status === 'failed')
    return (
      <Navigate
        to={`${FEATURES.membership.path}/${templateAliasCode}/signup/top`}
      />
    );

  // 会員未連携ユーザーのみアクセスできるページの場合: 会員連携済みユーザーはテンプレートに応じたトップへ
  if (!isRequire && membershipMemberResponse.status === 'succeeded') {
    // NOTE: パラメター付いてる場合、こちらで捌くと長くなるので、そのまま新しいURLに渡す
    const urlParam = window.location.search;
    return (
      <Navigate
        to={`${FEATURES.membership.path}/${templateAliasCode}${urlParam ?? ''}`}
      />
    );
  }

  return <>{children}</>;
};

export default MemberVerifier;
