/**
 * mercury pluginにおけるfeature_codesのapiのキーをまとめた定数
 * pepper template optionで利用
 * */
export const MERCURY_FEATURE_CODES = {
  // カード番号を登録する機能
  LINK_MEMBER: 'link_member',
  // カード番号を更新する機能
  CHANGE_CARD_NUMBER: 'change_card_number'
} as const;
