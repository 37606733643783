import { yupResolver } from '@hookform/resolvers/yup';
import dispatchToast from 'components/modules/Toast';
import {
  patchMembershipLinkCardConfirmation,
  postMembershipLinkCardConfirmation
} from 'features/membership/api';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { MEMBER_FORM_ERROR_MESSAGES } from 'features/membership/libs/const/cuminMemberFormValidation';
import cuminFormatDataForApi from 'features/membership/libs/cuminFormatDataForApi';
import { defaultValidationRules } from 'features/membership/libs/validate';
import { CardLinkFormInputs } from 'features/membership/types/form';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { BaseSyntheticEvent, useCallback, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { object, string } from 'yup';

type UseCardLinkFormReturn = {
  isMutating: boolean;
  methods: UseFormReturn<CardLinkFormInputs>;
  formSubmitHandler: (event?: BaseSyntheticEvent) => Promise<void>;
};

/**
 * Cuminのカード連携・再連携で利用するhooksをまとめたCustom Hook
 *
 * @param confirmPagePath 確認API実行後に遷移する確認画面のpath
 */
export const useCardLinkForm = (
  confirmPagePath: string
): UseCardLinkFormReturn => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  // カード連携もしくはカード再連携かのチェックとplugin optionの存在チェックを行いAPI実行関数を返却する
  const selectConfirmApi = () => {
    if (pathname.includes(`/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/link`)) {
      return postMembershipLinkCardConfirmation; // カード連携入力確認API（CSD連携）
    }
    if (
      pathname.includes(
        `/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/card-renewal/relink`
      )
    ) {
      return patchMembershipLinkCardConfirmation; // カード再連携確認API（CSD連携）
    }
    return null;
  };

  const [{ loading: isMutating }, confirmFormData] = useAsyncFn<
    (formData: Partial<CardLinkFormInputs>) => Promise<void>
  >(async (formData) => {
    // 利用するAPI実行関数を取得
    const confirmLinkCard = selectConfirmApi();
    if (typeof formData === 'undefined' || !confirmLinkCard) return;

    const res = await confirmLinkCard(cuminFormatDataForApi(formData));
    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      navigate(confirmPagePath, {
        state: { formData }
      });
      return;
    }

    if (res.error_fields && Object.keys(res.error_fields).length) {
      setServerErrors(res.error_fields);
      return;
    }

    // 個別な項目じゃない問題でエラーした場合
    dispatchToast({
      id: 'membership-member-api-error',
      toastText: res.errors?.join('\n')
    });
  }, []);

  const schema = object({
    card_number: string()
      .required(MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE)
      .matches(/^\d{1,256}$/, '半角数字256文字以内で入力してください'),
    birthday: defaultValidationRules.birthday.concat(
      object({
        year: string().required(
          MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE
        ),
        month: string().required(
          MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE
        ),
        date: string().required(
          MEMBER_FORM_ERROR_MESSAGES.REQUIRED_FIELD_MESSAGE
        )
      })
    )
  });

  const methods = useForm<CardLinkFormInputs>({
    // NOTE: onBlurだと初回入力時にバリデーショントリガーされないので、onChangeに設定
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      ...(state?.formData ?? {
        card_number: '',
        birthday: { year: '', month: '', date: '' }
      })
    }
  });

  const setServerErrors = useCallback<
    (errors: Record<string, string[]>) => void
  >(
    (errors) => {
      Object.entries(errors).forEach(([fieldName, errorsArr]) => {
        switch (fieldName) {
          case 'card_number':
            methods.setError('card_number', {
              message: (errorsArr as string[]).join('、')
            });
            break;
          case 'birthday':
            methods.setError('birthday.year', {
              message: (errorsArr as string[]).join('、')
            });
            break;
          case 'base':
            methods.setError('root', {
              message: (errorsArr as string[]).join('、')
            });
        }
      });
    },
    [methods]
  );

  const formSubmitHandler = methods.handleSubmit(
    async (formData) => await confirmFormData(formData)
  );

  useEffect(() => {
    if (state?.formData) {
      methods.reset(state.formData);
    }
  }, [state, methods]);

  useEffect(() => {
    if (!state?.errors) {
      return;
    }
    setServerErrors(state.errors);
  }, [state?.errors, setServerErrors]);

  return {
    isMutating,
    methods,
    formSubmitHandler
  };
};
