import dispatchToast from 'components/modules/Toast';
import { postMembershipIssueCardConfirmation } from 'features/membership/api';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import cuminFormatDataForApi from 'features/membership/libs/cuminFormatDataForApi';
import {
  cuminMemberFormRequiredFieldsState,
  cuminMemberFormVisibleFieldsState
} from 'features/membership/libs/recoil/selector';
import {
  CuminIssueCardFormInput,
  CuminUserProfileFormInput
} from 'features/membership/types/form';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { BaseSyntheticEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useRecoilValue } from 'recoil';
import { useMemberForm } from './useMemberForm';

type UseCardIssueFormReturn = {
  isMutating: boolean;
  methods: UseFormReturn<CuminIssueCardFormInput | CuminUserProfileFormInput>;
  formSubmitHandler: (event?: BaseSyntheticEvent) => Promise<void>;
};

const useCardIssueForm = (): UseCardIssueFormReturn => {
  const navigate = useNavigate();
  const { state } = useLocation();

  /**
   * Cumin template の中で表示する項目名一覧
   */
  const cuminMemberFormVisibleFields = useRecoilValue(
    cuminMemberFormVisibleFieldsState
  );
  /**
   * Cumin template の中で必須入力項目名一覧
   */
  const cuminProfileRequiredFields = useRecoilValue(
    cuminMemberFormRequiredFieldsState
  );

  const { methods, setServerErrors } = useMemberForm({
    defaultValues: state?.formData,
    formFields: cuminMemberFormVisibleFields,
    requiredFormFields: cuminProfileRequiredFields
  });

  const [{ loading: isMutating }, confirmFormData] = useAsyncFn<
    (formData: Partial<CuminIssueCardFormInput>) => Promise<void>
  >(async (formData) => {
    if (typeof formData === 'undefined') return undefined;

    const res = await postMembershipIssueCardConfirmation(
      cuminFormatDataForApi(formData)
    );
    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      navigate(`/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/issue/confirm`, {
        state: { formData }
      });
      return;
    }

    if (res.error_fields && Object.keys(res.error_fields).length) {
      setServerErrors(res.error_fields);
      return;
    }

    // 個別な項目じゃない問題でエラーした場合
    dispatchToast({
      id: 'membership-member-api-error',
      toastText: res.errors?.join('\n')
    });
  }, []);

  const formSubmitHandler = methods.handleSubmit(
    async (formData) => await confirmFormData(formData)
  );

  return {
    isMutating,
    methods,
    formSubmitHandler
  };
};
export default useCardIssueForm;
