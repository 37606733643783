import dispatchToast from 'components/modules/Toast';
import { getUserProfile } from 'features/membership/api';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { membershipProfileResponseAtom } from 'libs/recoil/atom';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { useRecoilState } from 'recoil';

/**
 * 会員情報取得カスタムフック
 * @returns membershipProfileResponse: 会員情報、 isMutating: 会員情報取得中フラグ
 */
export const useMembershipProfileResponse = () => {
  const [membershipProfileResponse, setMembershipProfileResponse] =
    useRecoilState(membershipProfileResponseAtom);

  const [{ loading: isMutating }, executeApi] = useAsyncFn(async () => {
    // 会員情報を取得
    const res = await getUserProfile();

    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      // 成功の時、会員情報を設定
      setMembershipProfileResponse(res.data);
      return;
    }

    // 失敗時はエラートーストを表示
    dispatchToast({
      id: 'membership-member-api-error',
      toastText: res.errors.join('\n')
    });
  });

  // 画面初回表示時に会員情報を取得する
  useEffectOnce(() => {
    // すでに会員情報を取得済みの場合はスキップ
    if (membershipProfileResponse) return;

    executeApi();
  });

  return { membershipProfileResponse, isMutating };
};
