import { Button, Heading, Stack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import { FC, MouseEventHandler, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

type CuminCardLinkConfirmPageProps = {
  formPagePath: string;
  onClickRegisterButton: MouseEventHandler;
  onClickBackButton: MouseEventHandler;
  isMutating?: boolean;
  cardInfo?: {
    card_number: string | unknown;
    birthday: {
      year: string | unknown;
      month: string | unknown;
      date: string | unknown;
    };
  };
  additionalLabeledValue?: ReactElement;
};

/**
 * カード連携・再連携確認コンポーネント
 * cuminテンプレートで利用する共通コンポーネント
 */
const CuminCardLinkConfirmPage: FC<CuminCardLinkConfirmPageProps> = ({
  formPagePath,
  onClickRegisterButton,
  onClickBackButton,
  isMutating,
  cardInfo,
  additionalLabeledValue
}) => {
  if (
    !cardInfo ||
    typeof cardInfo.card_number !== 'string' ||
    typeof cardInfo.birthday.year !== 'string' ||
    typeof cardInfo.birthday.month !== 'string' ||
    typeof cardInfo.birthday.date !== 'string' ||
    cardInfo.card_number === '' ||
    cardInfo.birthday.year === '' ||
    cardInfo.birthday.month === '' ||
    cardInfo.birthday.date === ''
  )
    return <Navigate to={formPagePath} />;

  return (
    <MainLayout header>
      <Stack pt="3rem" px="1rem" h="100%" justifyContent="space-between">
        <Stack spacing="1.5rem">
          {/* タイトル */}
          <Heading fontSize="xl" fontWeight="700">
            入力内容を確認してください
          </Heading>

          {/* カード番号 */}
          <LabeledValue label="カード番号" value={cardInfo.card_number} />

          {/* 生年月日 */}
          <LabeledValue
            label="生年月日"
            value={`${cardInfo.birthday.year}年${cardInfo.birthday.month}月${cardInfo.birthday.date}日`}
          />

          {/* その他の項目 */}
          {additionalLabeledValue}
        </Stack>

        <Stack gap="1rem">
          <Button
            variant="primary-fullwidth-rounded"
            isLoading={isMutating}
            onClick={onClickRegisterButton}
          >
            登録する
          </Button>

          <Button
            variant="primary-outline-fullwidth-rounded"
            isLoading={isMutating}
            onClick={onClickBackButton}
          >
            修正する
          </Button>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default CuminCardLinkConfirmPage;
