import { Skeleton, Stack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import CuminRenewalFeatureArea from 'features/membership/components/cumin/CuminRenewalFeatureArea';
import FeatureVerifier from 'features/membership/components/FeatureVerifier';
import { useMembershipProfileResponse } from 'features/membership/hooks/cumin/useMembershipProfileResponse';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { EARTH_FEATURE_CODES } from 'features/membership/libs/const/earthFeatureCodes';
import { REGISTER_TYPE } from 'features/membership/libs/const/registerType';
import {
  CuminPagePageTemplateOptionKey,
  membershipPluginFeatureState,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import _ from 'lodash';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * カード紛失・再発行後のお手続き
 */
const CuminCardRenewal: FC = () => {
  const { membershipProfileResponse, isMutating } =
    useMembershipProfileResponse();
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.CUMIN));

  const reissueTitleContent =
    templateOptions?.renewalReissueTitleContentOption?.content ?? '';
  const reissueExplanationContent =
    templateOptions?.renewalReissueExplanationContentOption?.content ?? '';
  const reissueNoticeContent =
    templateOptions?.renewalReissueNoticeContentOption?.content ?? '';
  // digiclue側に確認してボタンテキストが未設定の場合、デフォルトテキストを表示するようになった
  const reissueButtonContent =
    templateOptions?.renewalReissueButtonContentOption?.content ?? '再発行する';

  const relinkTitleContent =
    templateOptions?.renewalRelinkTitleContentOption?.content ?? '';
  const relinkExplanationContent =
    templateOptions?.renewalRelinkExplanationContentOption?.content ?? '';
  const relinkNoticeContent =
    templateOptions?.renewalRelinkNoticeContentOption?.content ?? '';
  // digiclue側に確認してボタンテキストが未設定の場合、デフォルトテキストを表示するようになった
  const relinkButtonContent =
    templateOptions?.renewalRelinkButtonContentOption?.content ??
    '別のカードを連携する';

  const earthFeatureCodes = useRecoilValue(membershipPluginFeatureState).earth;
  const isReissueFeatureActive = Boolean(
    earthFeatureCodes?.includes(_.camelCase(EARTH_FEATURE_CODES.REISSUE))
  );
  const isRelinkFeatureActive = Boolean(
    earthFeatureCodes?.includes(_.camelCase(EARTH_FEATURE_CODES.RELINK))
  );
  const isGetMemberFeatureActive = Boolean(
    earthFeatureCodes?.includes(_.camelCase(EARTH_FEATURE_CODES.GET_MEMBER))
  );

  // ユーザーの登録タイプが付番もしくは再発行ではないかの確認フラグ
  const isNotIssueRegistered =
    membershipProfileResponse?.register_type !== REGISTER_TYPE.ISSUANCE &&
    membershipProfileResponse?.register_type !== REGISTER_TYPE.REISSUANCE;

  const showReissueArea =
    isReissueFeatureActive && isGetMemberFeatureActive && isNotIssueRegistered;

  return (
    <MainLayout header footer bg="dark.50">
      <FeatureVerifier
        hasActiveFeatures={isReissueFeatureActive || isRelinkFeatureActive}
        templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
      >
        <Stack px="1rem" pt="3rem">
          <Skeleton isLoaded={!isMutating}>
            {/* デジタルカードを発行する */}
            {showReissueArea && (
              <CuminRenewalFeatureArea
                titleContent={reissueTitleContent}
                explanationContent={reissueExplanationContent}
                noticeContent={reissueNoticeContent}
                buttonContent={reissueButtonContent}
                buttonLink="reissue"
              />
            )}
          </Skeleton>
          <Skeleton isLoaded={!isMutating}>
            {/* 再発行したカードを連携する */}
            {isRelinkFeatureActive && (
              <CuminRenewalFeatureArea
                titleContent={relinkTitleContent}
                explanationContent={relinkExplanationContent}
                noticeContent={relinkNoticeContent}
                buttonContent={relinkButtonContent}
                buttonLink="relink"
                buttonVariant="primary-outline-fullwidth-rounded"
              />
            )}
          </Skeleton>
        </Stack>
      </FeatureVerifier>
    </MainLayout>
  );
};

export default CuminCardRenewal;
