import { Stack, useDisclosure } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import dayjs from 'dayjs';
import CuminBarcodeBox from 'features/membership/components/cumin/CuminBarcodeBox';
import CuminOvertimePointGetCautionModal from 'features/membership/components/cumin/CuminOvertimePointGetCautionModal';
import CuminPointBox from 'features/membership/components/cumin/CuminPointBox';
import useCardPoints from 'features/membership/hooks/cumin/useCardPoints';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  CuminPagePageTemplateOptionKey,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { FC, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useRecoilValue } from 'recoil';

/**
 * トップ
 */
const CuminTop: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // 表示する更新日時を管理
  const [updateTime, setUpdateTime] = useState(dayjs().format('HH:mm'));
  const membershipMemberResponse = useRecoilValue(membershipMemberResponseAtom);
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.CUMIN));
  // 会員番号
  const cardNumber =
    membershipMemberResponse && membershipMemberResponse.status === 'succeeded'
      ? membershipMemberResponse.data.card_number
      : '';

  const { data, serviceTime, isMutating, executeApi } = useCardPoints(
    cardNumber,
    onOpen
  );

  // ポイント残高API実行が完了した際に、カードに表示する日にちを更新する
  const updateButtonClickHandler = async () => {
    await executeApi();
    setUpdateTime(dayjs().format('HH:mm'));
  };

  // 初回実行
  useEffectOnce(() => {
    executeApi();
  });

  return (
    <MainLayout header footer bg="dark.50">
      <Stack px="1rem" pt="3rem" spacing="1.25rem">
        <CuminBarcodeBox
          cardNumber={cardNumber}
          imageUrl={templateOptions?.topImageOption?.image_url}
          barcodeFormat="EAN13"
        />
        <CuminPointBox
          onUpdateButtonClick={updateButtonClickHandler}
          updateTime={updateTime}
          isMutating={isMutating}
          isPointExpiryDateVisible={Boolean(
            templateOptions?.topPointExpiryDateVisibleOption
          )}
          unavailableHour={serviceTime?.unavailable_hour}
          pointData={data}
        />
        <CuminOvertimePointGetCautionModal
          isOpen={isOpen}
          onClose={onClose}
          isLoaded={!isMutating}
          availableHour={serviceTime?.available_hour}
        />
      </Stack>
    </MainLayout>
  );
};

export default CuminTop;
