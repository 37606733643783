import { Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type CuminRenewalFeatureAreaProps = {
  titleContent: string;
  explanationContent: string;
  noticeContent: string;
  buttonContent: string;
  buttonLink: string;
  buttonVariant?:
    | 'primary-fullwidth-rounded'
    | 'primary-outline-fullwidth-rounded';
};

const CuminRenewalFeatureArea: FC<CuminRenewalFeatureAreaProps> = ({
  titleContent,
  explanationContent,
  noticeContent,
  buttonContent,
  buttonLink,
  buttonVariant = 'primary-fullwidth-rounded'
}) => {
  return (
    <VStack
      p="4"
      bg="white"
      borderRadius="lg"
      gap="4"
      alignItems="start"
      w="100%"
    >
      {!!titleContent && (
        <Heading
          fontSize="xl"
          fontWeight="700"
          wordBreak="break-all"
          whiteSpace="pre-wrap"
        >
          {titleContent}
        </Heading>
      )}

      {!!explanationContent && (
        <Text fontSize="lg" wordBreak="break-all" whiteSpace="pre-wrap">
          {explanationContent}
        </Text>
      )}

      <Center w="100%">
        <Button
          as={RouterLink}
          to={buttonLink}
          variant={buttonVariant}
          whiteSpace="pre-wrap"
          wordBreak="break-all"
        >
          {buttonContent}
        </Button>
      </Center>

      {!!noticeContent && (
        <Text
          fontSize="md"
          color="dark.400"
          wordBreak="break-all"
          whiteSpace="pre-wrap"
        >
          {noticeContent}
        </Text>
      )}
    </VStack>
  );
};

export default CuminRenewalFeatureArea;
