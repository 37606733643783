import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import liff from '@line/liff';
import StringToJsx from 'components/modules/StringToJsx';
import { FC } from 'react';

import { TENANT_CODE } from 'libs/constants';

export const getErrorHeading = (code: string) => {
  if (code === 'server-error') {
    return 'サーバーエラー';
  } else if (code === 'session-expired') {
    return 'セッションの有効期限切れ';
  } else if (code === 'create-user-failure') {
    return 'ユーザー登録失敗';
  } else if (code === 'invalid-tenant-code') {
    return 'テナント情報取得エラー';
  } else {
    return 'エラー';
  }
};

export const getErrorText = (code: string) => {
  if (code === 'server-error') {
    return 'サーバー側でエラーが発生しました。\r\nアプリを再起動してください。';
  } else if (code === 'session-expired') {
    return 'セッションの有効期限が切れています。\r\nアプリを再起動してください。';
  } else if (code === 'create-user-failure') {
    return 'アプリを再起動してください。';
  } else if (code === 'invalid-tenant-code') {
    return `テナント情報を取得できませんでした。\r\nアプリを再起動してください。\r\nテナントコード:${TENANT_CODE}`;
  } else {
    return 'エラーが発生しました。\r\nアプリを再起動してください。';
  }
};

const ErrorPage: FC = () => {
  const paramStr = window.location.search;
  const searchParams = new URLSearchParams(paramStr);
  const errorCode = searchParams.get('error_code') || '';

  return (
    <Flex h="100vh" flexDir="column" justifyContent="center">
      <Box
        display="flex"
        flexDir="column"
        px="2.5rem"
        alignItems="center"
        textAlign="center"
      >
        <Box
          rounded="full"
          border="1px solid"
          p="1rem"
          borderColor="red.500"
          mb="1rem"
        >
          <CloseIcon w={8} h={8} color="red.500" />
        </Box>
        <Heading mb="1rem">{getErrorHeading(errorCode)}</Heading>
        <StringToJsx
          text={getErrorText(errorCode)}
          fontSize="md"
          fontWeight={400}
        />
        <Button
          width="100%"
          rounded="full"
          variant="outline"
          mt="3rem"
          onClick={() => liff.closeWindow()}
        >
          アプリを閉じる
        </Button>
      </Box>
    </Flex>
  );
};

export default ErrorPage;
