/** templateのエイリアスコードをまとめた定数 */
export const TEMPLATE_ALIAS_CODE = {
  SIMPLE: 'simple',
  PEPPER: 'pepper',
  MINT: 'mint',
  CUMIN: 'cumin'
} as const;

/** pluginのエイリアスコードをまとめた定数 */
export const PLUGIN_ALIAS_CODE = {
  MERCURY: 'mercury',
  VENUS: 'venus',
  EARTH: 'earth'
} as const;
