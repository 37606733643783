import CuminCardLinkConfirmPage from 'features/membership/components/cumin/CuminCardLinkConfirmPage';
import useCardLinkConfirm from 'features/membership/hooks/cumin/useCardLinkConfirm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { FC } from 'react';

/**
 * カード再連携/確認
 */
const CuminCardRelinkConfirm: FC = () => {
  const formPagePath = `/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/card-renewal/relink`;
  const completePagePath = `/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/card-renewal/relink/complete`;
  const { onClickRegisterButton, onClickBackButton, isMutating, cardInfo } =
    useCardLinkConfirm({
      completePagePath,
      formPagePath
    });

  return (
    <CuminCardLinkConfirmPage
      formPagePath={formPagePath}
      onClickRegisterButton={onClickRegisterButton}
      onClickBackButton={onClickBackButton}
      isMutating={isMutating}
      cardInfo={cardInfo}
    />
  );
};

export default CuminCardRelinkConfirm;
