import { Checkbox, HStack, Text } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { ChangeEvent, FC, MouseEventHandler } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type TermOfServiceProps = {
  variant: 'primary' | 'secondary';
  content: string;
  isChecked?: boolean;
  checkboxProps?: UseFormRegisterReturn;
  onCheckboxChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onLinkClick: MouseEventHandler;
};

/**
 * 利用規約チェックボックスコンポーネント
 */
const TermOfService: FC<TermOfServiceProps> = ({
  variant,
  content,
  isChecked,
  checkboxProps,
  onCheckboxChange,
  onLinkClick
}) => (
  <HStack gap="0.375rem">
    <Checkbox
      variant={variant}
      isChecked={isChecked}
      onChange={onCheckboxChange}
      {...checkboxProps}
    />
    <Text
      fontWeight={400}
      fontSize="md"
      wordBreak="break-all"
      whiteSpace="pre-wrap"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content) // XSS 対策
      }}
      css={{
        a: {
          color:
            variant === 'secondary'
              ? 'var(--chakra-colors-secondary)'
              : 'var(--chakra-colors-primary)'
        }
      }}
      onClick={onLinkClick}
    />
  </HStack>
);

export default TermOfService;
