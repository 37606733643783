import CuminCardLinkFormPage from 'features/membership/components/cumin/CuminCardLinkFormPage';
import { useCardLinkForm } from 'features/membership/hooks/cumin/useCardLinkForm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  CuminPagePageTemplateOptionKey,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * カード再連携/入力
 */
const CuminCardRelink: FC = () => {
  const confirmPagePath = `/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/card-renewal/relink/confirm`;
  const { isMutating, methods, formSubmitHandler } =
    useCardLinkForm(confirmPagePath);
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.CUMIN));

  return (
    <CuminCardLinkFormPage
      isMutating={isMutating}
      methods={methods}
      onFormSubmit={formSubmitHandler}
      templateOptions={templateOptions}
      isTermAvailable={false}
    />
  );
};

export default CuminCardRelink;
