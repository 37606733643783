import {
  CardLinkFormInputs,
  CuminIssueCardFormInput,
  CuminIssueCardRequestParams,
  CuminUserProfileFormInput,
  CuminUserProfileRequestParams
} from 'features/membership/types/form';

type ValueSet =
  | CuminIssueCardRequestParams
  | CuminUserProfileRequestParams
  | CardLinkFormInputs;

/**
 * フォームで入力したデータを対象APIのリクエストパラメータ用に変換する関数
 * 対象API：
 *   - カード番号付番入力確認API（CSD連携）
 *   - カード番号付番API（CSD連携）
 *   - カード連携入力確認API（CSD連携）
 *   - カード番号連携API（CSD連携）
 *   - カード再連携確認API（CSD連携）
 *   - カード再連携API（CSD連携）
 *   - ユーザー情報編集API（CSD連携）
 * @param localFormData フォームデータ
 * @returns
 */
const cuminFormatDataForApi = (
  localFormData: Partial<
    CuminIssueCardFormInput | CuminUserProfileFormInput | CardLinkFormInputs
  >
) =>
  Object.entries(localFormData)
    .map(([fieldName, fieldValue]) => {
      const valueSet: ValueSet = {};
      // fieldValueがnullの可能性もある
      if (fieldName === 'full_name' && fieldValue) {
        // CSD側のルールで姓名の間は全角スペース、入力されてなかったらnull
        const valArr = Object.values(fieldValue);
        valueSet[fieldName] = !valArr.includes('')
          ? Object.values(fieldValue).join('　')
          : null;
      }
      // fieldValueがnullの可能性もある
      if (fieldName === 'full_name_kana' && fieldValue) {
        // CSD側のルールで姓名かなの間は半角スペース、入力されてなかったらnull
        const valArr = Object.values(fieldValue);
        valueSet[fieldName] = !valArr.includes('')
          ? Object.values(fieldValue).join(' ')
          : null;
      }
      // fieldValueがnullの可能性もある
      if (fieldName === 'birthday' && fieldValue) {
        const valArr = Object.values(fieldValue);
        valueSet[fieldName] = !valArr.includes('')
          ? valArr
              .map((dateVal) => (dateVal as string).padStart(2, '0'))
              .join('-')
          : null;
      }
      if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
        valueSet[fieldName as keyof ValueSet] =
          fieldValue !== '' ? fieldValue : null;
      }
      return valueSet;
    })
    .reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        ...currentValue
      }),
      {}
    );

export default cuminFormatDataForApi;
