import AliasCodeVerifier from 'features/membership/components/AliasCodeVerifier';
import MembershipRoot from 'features/membership/components/MembershipRoot';
import MemberVerifier from 'features/membership/components/MemberVerifier';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { FEATURES } from 'libs/features';
import CouponTop from 'pages/coupon/CouponTop';
import NotFound from 'pages/error/NotFound';
import CuminCardIssue from 'pages/membership/cumin/CuminCardIssue';
import CuminCardIssueComplete from 'pages/membership/cumin/CuminCardIssueComplete';
import CuminCardIssueConfirm from 'pages/membership/cumin/CuminCardIssueConfirm';
import CuminCardLink from 'pages/membership/cumin/CuminCardLink';
import CuminCardLinkComplete from 'pages/membership/cumin/CuminCardLinkComplete';
import CuminCardLinkConfirm from 'pages/membership/cumin/CuminCardLinkConfirm';
import CuminCardReissue from 'pages/membership/cumin/CuminCardReissue';
import CuminCardReissueComplete from 'pages/membership/cumin/CuminCardReissueComplete';
import CuminCardRelink from 'pages/membership/cumin/CuminCardRelink';
import CuminCardRelinkComplete from 'pages/membership/cumin/CuminCardRelinkComplete';
import CuminCardRelinkConfirm from 'pages/membership/cumin/CuminCardRelinkConfirm';
import CuminCardRenewal from 'pages/membership/cumin/CuminCardRenewal';
import CuminProfile from 'pages/membership/cumin/CuminProfile';
import CuminProfileEdit from 'pages/membership/cumin/CuminProfileEdit';
import CuminSignupTop from 'pages/membership/cumin/CuminSignupTop';
import CuminTop from 'pages/membership/cumin/CuminTop';
import MembershipTop from 'pages/membership/MembershipTop';
import MintComplete from 'pages/membership/mint/MintComplete';
import MintConfirm from 'pages/membership/mint/MintConfirm';
import MintEdit from 'pages/membership/mint/MintEdit';
import MintSignupComplete from 'pages/membership/mint/MintSignupComplete';
import MintSignupConfirm from 'pages/membership/mint/MintSignupConfirm';
import MintSignupNew from 'pages/membership/mint/MintSignupNew';
import MintSignupTop from 'pages/membership/mint/MintSignupTop';
import MintTop from 'pages/membership/mint/MintTop';
import PepperComplete from 'pages/membership/pepper/PepperComplete';
import PepperConfirm from 'pages/membership/pepper/PepperConfirm';
import PepperEdit from 'pages/membership/pepper/PepperEdit';
import PepperSignupComplete from 'pages/membership/pepper/PepperSignupComplete';
import PepperSignupConfirm from 'pages/membership/pepper/PepperSignupConfirm';
import PepperSignupNew from 'pages/membership/pepper/PepperSignupNew';
import PepperSignupTop from 'pages/membership/pepper/PepperSignupTop';
import PepperTop from 'pages/membership/pepper/PepperTop';
import SimpleComplete from 'pages/membership/simple/SimpleComplete';
import SimpleConfirm from 'pages/membership/simple/SimpleConfirm';
import SimpleEdit from 'pages/membership/simple/SimpleEdit';
import SimpleSignupComplete from 'pages/membership/simple/SimpleSignupComplete';
import SimpleSignupConfirm from 'pages/membership/simple/SimpleSignupConfirm';
import SimpleSignupNew from 'pages/membership/simple/SimpleSignupNew';
import SimpleSignupTop from 'pages/membership/simple/SimpleSignupTop';
import SimpleTop from 'pages/membership/simple/SimpleTop';
import Menu from 'pages/menu/Menu';
import MyStoreList from 'pages/my-store/MyStoreList';
import MyStoreRegister from 'pages/my-store/MyStoreRegister';
import NotificationDetail from 'pages/notification/NotificationDetail';
import NotificationList from 'pages/notification/NotificationList';
import PointCouponTop from 'pages/point-coupon/PointCouponTop';
import RedirectDigiclueplus from 'pages/redirect/RedirectDigiclueplus';
import CampaignGroupDetail from 'pages/reservations/CampaignGroupDetail';
import CampaignGroupsList from 'pages/reservations/CampaignGroupsList';
import CampaignsList from 'pages/reservations/CampaignsList';
import ReservationCart from 'pages/reservations/ReservationCart';
import ReservationCompleted from 'pages/reservations/ReservationCompleted';
import ReservationCustomerForm from 'pages/reservations/ReservationCustomerForm';
import ReservationDetail from 'pages/reservations/ReservationDetail';
import ReservationFinalConfirmation from 'pages/reservations/ReservationFinalConfirmation';
import ReservationHistory from 'pages/reservations/ReservationHistory';
import ReservationPickup from 'pages/reservations/ReservationPickup';
import ReservationPickupCompleted from 'pages/reservations/ReservationPickupCompleted';
import StampTop from 'pages/stamps/StampTop';
import Top from 'pages/Top';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

const AppRoutes: FC = () => (
  <Routes>
    {/* common */}
    <Route path="/" element={<Top />} />
    <Route path="*" element={<NotFound />} />
    <Route path="/not-found" element={<NotFound />} />

    {/* menu */}
    <Route path="/menu" element={<Menu />} />

    {/* reservations */}
    {/* キャンペーン一覧 */}
    <Route path="/reservations" element={<CampaignsList />} />
    {/* 履歴一覧（予約一覧） */}
    <Route path="/reservations/history" element={<ReservationHistory />} />
    {/* 履歴詳細（予約詳細） */}
    <Route
      path="/reservations/history/:reservationId"
      element={<ReservationDetail />}
    />
    {/* 受取 */}
    <Route
      path="/reservations/history/:reservationId/pickup"
      element={<ReservationPickup />}
    />
    {/* 受取完了 */}
    <Route
      path="/reservations/history/:reservationId/pickup/completed"
      element={<ReservationPickupCompleted />}
    />
    {/* キャンペーン詳細（キャンペーングループ一覧） */}
    <Route path="/reservations/:campaignId" element={<CampaignGroupsList />} />
    {/* キャンペーングループ詳細 */}
    <Route
      path="/reservations/:campaignId/:campaignGroupId"
      element={<CampaignGroupDetail />}
    />
    {/* カート */}
    <Route
      path="/reservations/:campaignId/cart"
      element={<ReservationCart />}
    />
    {/* 予約フォーム（受取設定） */}
    <Route
      path="/reservations/:campaignId/customer-form"
      element={<ReservationCustomerForm />}
    />
    {/* 予約内容確認 */}
    <Route
      path="/reservations/:campaignId/confirm"
      element={<ReservationFinalConfirmation />}
    />
    {/* 予約完了 */}
    <Route
      path="/reservations/:campaignId/completed"
      element={<ReservationCompleted />}
    />

    {/* my-store */}
    {/* マイ店舗 */}
    <Route path="/my-store/" element={<MyStoreList />} />
    {/* マイ店舗登録 */}
    <Route path="/my-store/register" element={<MyStoreRegister />} />

    {/* notifications */}
    {/* お知らせ一覧 */}
    <Route path="/notifications" element={<NotificationList />} />
    {/* お知らせ詳細 */}
    <Route
      path="/notifications/:notificationId"
      element={<NotificationDetail />}
    />

    {/* coupon */}
    {/* クーポントップ */}
    <Route
      path={FEATURES.digiclueplus_simple_coupons.path}
      element={<CouponTop />}
    />

    {/* point-coupon */}
    <Route
      path={FEATURES.digiclueplus_point_coupons.path}
      element={<PointCouponTop />}
    />

    {/* stamps */}
    <Route path={FEATURES.digiclueplus_stamps.path} element={<StampTop />} />

    {/* membership */}
    <Route path={FEATURES.membership.path} element={<MembershipRoot />}>
      {/* 会員連携トップ */}
      <Route index element={<MembershipTop />} />

      {/* 会員連携画面テンプレート: Simple */}
      <Route
        path={TEMPLATE_ALIAS_CODE.SIMPLE}
        element={
          <AliasCodeVerifier templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE} />
        }
      >
        {/* 会員登録トップ */}
        <Route
          path="signup/top"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
              isRequire={false}
            >
              <SimpleSignupTop />
            </MemberVerifier>
          }
        />
        {/* 会員登録入力 */}
        <Route
          path="signup/new"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
              isRequire={false}
            >
              <SimpleSignupNew />
            </MemberVerifier>
          }
        />
        {/* 会員登録入力確認 */}
        <Route
          path="signup/confirm"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
              isRequire={false}
            >
              <SimpleSignupConfirm />
            </MemberVerifier>
          }
        />
        {/* 会員登録完了 */}
        <Route
          path="signup/complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
              isRequire
            >
              <SimpleSignupComplete />
            </MemberVerifier>
          }
        />
        {/* トップ */}
        <Route
          index
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
              isRequire
            >
              <SimpleTop />
            </MemberVerifier>
          }
        />
        {/* 会員編集入力 */}
        <Route
          path="edit"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
              isRequire
            >
              <SimpleEdit />
            </MemberVerifier>
          }
        />
        {/* 会員編集入力確認 */}
        <Route
          path="confirm"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
              isRequire
            >
              <SimpleConfirm />
            </MemberVerifier>
          }
        />
        {/* 会員編集完了 */}
        <Route
          path="complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.SIMPLE}
              isRequire
            >
              <SimpleComplete />
            </MemberVerifier>
          }
        />
      </Route>

      {/* 会員連携画面テンプレート: Pepper */}
      <Route
        path={TEMPLATE_ALIAS_CODE.PEPPER}
        element={
          <AliasCodeVerifier templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER} />
        }
      >
        {/* 会員登録トップ */}
        <Route
          path="signup/top"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER}
              isRequire={false}
            >
              <PepperSignupTop />
            </MemberVerifier>
          }
        />
        {/* 会員登録入力 */}
        <Route
          path="signup/new"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER}
              isRequire={false}
            >
              <PepperSignupNew />
            </MemberVerifier>
          }
        />
        {/* 会員登録入力確認 */}
        <Route
          path="signup/confirm"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER}
              isRequire={false}
            >
              <PepperSignupConfirm />
            </MemberVerifier>
          }
        />
        {/* 会員登録完了 */}
        <Route
          path="signup/complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER}
              isRequire
            >
              <PepperSignupComplete />
            </MemberVerifier>
          }
        />
        {/* トップ */}
        <Route
          index
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER}
              isRequire
            >
              <PepperTop />
            </MemberVerifier>
          }
        />
        {/* 会員編集入力 */}
        <Route
          path="edit"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER}
              isRequire
            >
              <PepperEdit />
            </MemberVerifier>
          }
        />
        {/* 会員編集入力確認 */}
        <Route
          path="confirm"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER}
              isRequire
            >
              <PepperConfirm />
            </MemberVerifier>
          }
        />
        {/* 会員編集完了 */}
        <Route
          path="complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.PEPPER}
              isRequire
            >
              <PepperComplete />
            </MemberVerifier>
          }
        />
      </Route>
      {/* 会員連携画面テンプレート: Mint */}
      <Route
        path={TEMPLATE_ALIAS_CODE.MINT}
        element={
          <AliasCodeVerifier templateAliasCode={TEMPLATE_ALIAS_CODE.MINT} />
        }
      >
        {/* 会員登録トップ */}
        <Route
          path="signup/top"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
              isRequire={false}
            >
              <MintSignupTop />
            </MemberVerifier>
          }
        />
        {/* 会員登録入力 */}
        <Route
          path="signup/new"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
              isRequire={false}
            >
              <MintSignupNew />
            </MemberVerifier>
          }
        />
        {/* 会員登録入力確認 */}
        <Route
          path="signup/confirm"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
              isRequire={false}
            >
              <MintSignupConfirm />
            </MemberVerifier>
          }
        />
        {/* 会員登録完了 */}
        <Route
          path="signup/complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
              isRequire
            >
              <MintSignupComplete />
            </MemberVerifier>
          }
        />
        {/* トップ */}
        <Route
          index
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
              isRequire
            >
              <MintTop />
            </MemberVerifier>
          }
        />
        {/* 会員編集入力 */}
        <Route
          path="edit"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
              isRequire
            >
              <MintEdit />
            </MemberVerifier>
          }
        />
        {/* 会員編集入力確認 */}
        <Route
          path="confirm"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
              isRequire
            >
              <MintConfirm />
            </MemberVerifier>
          }
        />
        {/* 会員編集完了 */}
        <Route
          path="complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.MINT}
              isRequire
            >
              <MintComplete />
            </MemberVerifier>
          }
        />
      </Route>
      {/* 会員連携画面テンプレート: Cumin */}
      <Route
        path={TEMPLATE_ALIAS_CODE.CUMIN}
        element={
          <AliasCodeVerifier templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN} />
        }
      >
        {/* 初回起動_カード確認 */}
        <Route
          path="signup/top"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire={false}
            >
              <CuminSignupTop />
            </MemberVerifier>
          }
        />
        {/* トップ */}
        <Route
          index
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminTop />
            </MemberVerifier>
          }
        />
        {/* カード連携/入力 */}
        <Route
          path="link"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire={false}
            >
              <CuminCardLink />
            </MemberVerifier>
          }
        />

        {/* カード連携/確認 */}
        <Route
          path="link/confirm"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire={false}
            >
              <CuminCardLinkConfirm />
            </MemberVerifier>
          }
        />

        {/* カード連携/完了 */}
        <Route
          path="link/complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminCardLinkComplete />
            </MemberVerifier>
          }
        />

        {/* カード番号付番/入力 */}
        <Route
          path="issue"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire={false}
            >
              <CuminCardIssue />
            </MemberVerifier>
          }
        />

        {/* カード番号付番/確認 */}
        <Route
          path="issue/confirm"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire={false}
            >
              <CuminCardIssueConfirm />
            </MemberVerifier>
          }
        />

        {/* カード番号付番/完了 */}
        <Route
          path="issue/complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminCardIssueComplete />
            </MemberVerifier>
          }
        />

        {/* カード紛失・再発行後のお手続き */}
        <Route
          path="card-renewal"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminCardRenewal />
            </MemberVerifier>
          }
        />

        {/* カード再連携/入力 */}
        <Route
          path="card-renewal/relink"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminCardRelink />
            </MemberVerifier>
          }
        />

        {/* カード再連携/確認 */}
        <Route
          path="card-renewal/relink/confirm"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminCardRelinkConfirm />
            </MemberVerifier>
          }
        />

        {/* カード再連携/完了 */}
        <Route
          path="card-renewal/relink/complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminCardRelinkComplete />
            </MemberVerifier>
          }
        />

        {/* カード紛失・再発行後のお手続き_デジタルカード/発行 */}
        <Route
          path="card-renewal/reissue"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminCardReissue />
            </MemberVerifier>
          }
        />

        {/* カード紛失・再発行後のお手続き_デジタルカード/発行完了 */}
        <Route
          path="card-renewal/reissue/complete"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminCardReissueComplete />
            </MemberVerifier>
          }
        />

        {/* 会員情報の確認 */}
        <Route
          path="profile"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminProfile />
            </MemberVerifier>
          }
        />

        {/* 会員情報の変更 */}
        <Route
          path="profile/edit"
          element={
            <MemberVerifier
              templateAliasCode={TEMPLATE_ALIAS_CODE.CUMIN}
              isRequire
            >
              <CuminProfileEdit />
            </MemberVerifier>
          }
        />
      </Route>
    </Route>

    {/* QRコードリダイレクト */}
    {/* デジクルプラス */}
    <Route path="redirect/digiclueplus" element={<RedirectDigiclueplus />} />
  </Routes>
);

export default AppRoutes;
