import {
  Button,
  Container,
  Heading,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import { useMyStore } from 'features/my-store/hooks/useMyStore';
import { useStore } from 'features/my-store/hooks/useStore';
import { StoreType } from 'features/my-store/types/StoreType';
import NotificationListItemWrapper from 'features/notification/components/NotificationListItemWrapper';
import { useNotificationList } from 'features/notification/hooks/useNotificationList';
import { reactSelectStyles } from 'libs/react-select';
import { FC } from 'react';
import Select from 'react-select';

/** お知らせ一覧 */
const NotificationList: FC = () => {
  const {
    displayedNotificationList,
    unreadIds,
    setCurrentStoreId,
    readNotification,
    isDone: isDoneNotificationList
  } = useNotificationList();

  const {
    stores,
    isDone: isDoneStores,
    isFeatureActive: isStoreActive
  } = useStore();
  const { myStores, isDone: isDoneMyStores } = useMyStore();

  // マイ店舗機能がONになっているの場合は店舗選択肢表示
  let storeSelectBox;
  if (isStoreActive) {
    const isStoresLoaded = isDoneStores && isDoneMyStores;

    //ToDo:CampaignsListのgenerateStoreOptionと共通化
    const generateStoreOption = (store: StoreType) => ({
      value: store.id,
      label: store.name
    });

    //ToDo:CampaignsListのgroupedStoreOptionsと共通化
    const groupedStoreOptions = [
      {
        label: 'マイ店舗',
        options: myStores.map(generateStoreOption)
      },
      ...stores.map(({ prefecture, stores }) => ({
        label: prefecture,
        options: stores.map(generateStoreOption)
      }))
    ];

    // 店舗選択肢表示
    storeSelectBox = (
      <Skeleton mb="1.5rem" isLoaded={isStoresLoaded}>
        <Select<{
          value: number;
          label: string;
        }>
          options={groupedStoreOptions}
          onChange={(option) => {
            setCurrentStoreId(option?.value ?? null);
          }}
          onFocus={(e) => e.currentTarget.scrollIntoView()}
          styles={reactSelectStyles}
          placeholder="店舗を選択してください"
          noOptionsMessage={() => (
            <Text fontSize="md">検索されている店舗名はありません</Text>
          )}
          isMulti={false}
          isClearable
          isSearchable
        />
      </Skeleton>
    );
  }

  return (
    <MainLayout bg="dark.50" header footer>
      <Container px="1rem" pt="2.25rem">
        <Heading size="md" mb="1rem" display="flex">
          お知らせ
          <Skeleton marginLeft="auto" isLoaded={isDoneNotificationList}>
            <Button
              variant="primary-link"
              h="inherit"
              p={0}
              onClick={() => readNotification(unreadIds)}
            >
              すべて既読にする
            </Button>
          </Skeleton>
        </Heading>
        {storeSelectBox}
        <VStack gap="0.5rem">
          <NotificationListItemWrapper
            isLoaded={isDoneNotificationList}
            notificationList={displayedNotificationList}
            unreadIds={unreadIds}
            readNotification={readNotification}
          />
        </VStack>
      </Container>
    </MainLayout>
  );
};

export default NotificationList;
