import { Box, Image } from '@chakra-ui/react';
import { FC } from 'react';

import { Z_INDEX_CONST } from 'libs/constants';
import { tenantConfigurationAtom } from 'libs/recoil/atom';
import { activeFeaturesState } from 'libs/recoil/selector';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

type HeaderType = {
  replaceTrue?: true;
};

const Header: FC<HeaderType> = ({ replaceTrue = false }) => {
  const navigate = useNavigate();
  const tenantConfiguration = useRecoilValue(tenantConfigurationAtom);
  const activeFeatures = useRecoilValue(activeFeaturesState);

  const topLink = activeFeatures.length ? activeFeatures[0].path : '/not-found';

  return (
    <Box
      w="100%"
      h="2rem"
      bg="white"
      boxShadow="rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px"
      position="relative"
      zIndex={Z_INDEX_CONST.header}
      _before={{
        content: '""',
        bg: 'white',
        h: '4rem',
        w: '4rem',
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translate(-50%, -10%)',
        borderRadius: '50%'
      }}
      onClick={() => navigate(topLink, { replace: replaceTrue })}
    >
      <Link to={topLink}></Link>
      <Image
        src={tenantConfiguration.logo_image || ''}
        w="2rem"
        h="2rem"
        position="absolute"
        top="30%"
        left="50%"
        transform="translate(-50%, 0%)"
        objectFit="contain"
        alt="テナント画像"
      />
    </Box>
  );
};

export default Header;
