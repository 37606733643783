import {
  CuminUserProfileResponse,
  ErrorResponse,
  GetMembershipMemberResponse,
  GetMembershipSettingResponse
} from 'features/membership/types';
import { StoreType } from 'features/my-store/types/StoreType';
import { atom } from 'recoil';
import { TenantType } from 'types/TenantType';

export const tenantConfigurationAtom = atom<
  Omit<TenantType, 'line_channel_key' | 'liff_id' | 'tax_round'>
>({
  key: 'tenant-config',
  default: {
    name: '',
    brand: '',
    code: '',
    liff_url: '',
    features: [],
    feature_objects: [],
    logo_image: '',
    main_color: '#000000',
    sub_color: '#ffffff',
    external_links: [],
    scan_paymentable: false
  }
});

// 未読お知らせ状態
export const unreadNotificationAtom = atom<{ hasUnread: boolean }>({
  key: 'unreadNotification',
  default: {
    hasUnread: false
  }
});

/** 会員連携情報 */
export const membershipSettingAtom = atom<GetMembershipSettingResponse['data']>(
  {
    key: 'membershipSetting',
    default: { page_template: { alias_code: '', options: [] }, plugins: [] }
  }
);

/**
 * MBS-API003 会員情報照会API のレスポンス
 *
 * 未取得の場合は undefined
 * */
export const membershipMemberResponseAtom = atom<
  GetMembershipMemberResponse | ErrorResponse | undefined
>({
  key: 'membershipMemberResponse',
  default: undefined
});

/**
 * QRコード読み取り後、標準ブラウザ（デジクルプラス）からミニアプリへリダイレクトした時に付与されるパラメータ
 * https://www.notion.so/digiclue/QR-158d813fecdd47b1adbd76b812353485?pvs=4#e766ea5fcf1445e78cc0d09544dee980
 * */
export const digiclueplusRedirectParamsAtom = atom<{
  digiclueplus: string; // デジクルプラスのウォール画面にリダイレクトすることを識別するためのもの（固定値）
  wall_path: string; // WebViewにてリダイレクトしたい先のパス。リダイレクトしたい先によって値は変動します。
}>({
  key: 'digiclueplusRedirectParams',
  default: {
    digiclueplus: 'wall',
    wall_path: ''
  }
});

/**
 * 全店舗情報保存
 *
 * 未取得の場合は undefined
 * */
export const allStoresAtom = atom<StoreType[]>({
  key: 'allStores',
  default: []
});

/**
 * ユーザー情報表示API（CSD連携）のレスポンス
 *
 * 未取得の場合は undefined
 */
export const membershipProfileResponseAtom = atom<
  CuminUserProfileResponse['data'] | undefined
>({
  key: 'membershipProfileResponseAtom',
  default: undefined
});
