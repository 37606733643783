import dispatchToast from 'components/modules/Toast';
import {
  patchMembershipLinkCard,
  postMembershipLinkCard
} from 'features/membership/api';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import cuminFormatDataForApi from 'features/membership/libs/cuminFormatDataForApi';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { membershipMemberResponseAtom } from 'libs/recoil/atom';
import { MouseEventHandler, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { useSetRecoilState } from 'recoil';

type UseCardLinkConfirmProps = {
  completePagePath: string;
  formPagePath: string;
};

type UseCardLinkConfirmReturn = {
  onClickRegisterButton: MouseEventHandler;
  onClickBackButton: MouseEventHandler;
  isMutating?: boolean;
  cardInfo?: {
    card_number: string | unknown;
    birthday: {
      year: string | unknown;
      month: string | unknown;
      date: string | unknown;
    };
  };
};

/**
 * Cuminのカード連携・再連携確認で利用するhooksをまとめたCustom Hook
 */
const useCardLinkConfirm: (
  props: UseCardLinkConfirmProps
) => UseCardLinkConfirmReturn = ({ completePagePath, formPagePath }) => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const setMembershipMemberResponse = useSetRecoilState(
    membershipMemberResponseAtom
  );

  // カード連携もしくはカード再連携かのチェックとplugin optionの存在チェックを行いAPI実行関数を返却する
  const selectUpdateApi = () => {
    if (
      pathname.includes(`/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/link/confirm`)
    ) {
      return postMembershipLinkCard; // カード番号連携API（CSD連携）
    }
    if (
      pathname.includes(
        `/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/card-renewal/relink/confirm`
      )
    ) {
      return patchMembershipLinkCard; // カード再連携API（CSD連携）
    }
    return null;
  };

  const [{ loading: isMutating }, onClickRegisterButton] =
    useAsyncFn(async () => {
      // 利用するAPI実行関数を取得
      const updateLinkCard = selectUpdateApi();
      if (
        !Object.keys(state).length ||
        typeof state.formData.card_number !== 'string' ||
        typeof state.formData.birthday.year !== 'string' ||
        typeof state.formData.birthday.month !== 'string' ||
        typeof state.formData.birthday.date !== 'string' ||
        !updateLinkCard
      )
        return;

      const res = await updateLinkCard(cuminFormatDataForApi(state.formData));

      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
        setMembershipMemberResponse(undefined); // 会員情報 のレスポンス のグローバルステートを削除
        navigate(pathname, { replace: true }); // state を削除
        navigate(completePagePath); // 完了ページへ
        return;
      }

      // 個別な項目じゃない問題で引っかかった場合
      if (!(res.error_fields && Object.keys(res.error_fields).length)) {
        dispatchToast({
          id: 'membership-member-api-error',
          toastText: res.errors.join('\n')
        });
        return;
      }

      if (res.error_fields && Object.keys(res.error_fields).length) {
        navigate(formPagePath, {
          state: { formData: state.formData, errors: res.error_fields }
        });
        return;
      }

      dispatchToast({
        id: 'membership-member-api-error',
        toastText:
          'エラーが発生しております。暫く経ってからもう一度お試しください'
      });
    }, [state, pathname]);

  const onClickBackButton = useCallback<MouseEventHandler>(() => {
    navigate(formPagePath, { state });
  }, [state, formPagePath, navigate]);

  return {
    onClickRegisterButton,
    onClickBackButton,
    isMutating,
    cardInfo: state?.formData
  };
};

export default useCardLinkConfirm;
