import {
  getRequestHeader,
  handleMoveToErrorPage,
  handleResetLocalStorage
} from 'api';
import axios, { ResponseType } from 'axios';
import {
  NotificationDetailType,
  NotificationListItemType
} from 'features/notification/types/NotificationType';
import { NOTIFICATIONS_API_ENDPOINT, TENANT_CODE } from 'libs/constants';

export const noticeApi = axios.create({
  baseURL: `${NOTIFICATIONS_API_ENDPOINT}/${TENANT_CODE}/notice`
});

// TODO: 今後の対応でも構いませんので、responseとerrorのformattingはinterceptorの方でやって、共通にしましょう。
noticeApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    // NOTE: Move the user to /error page in case of the error response with 500 status.
    if (error.response?.status === 500) {
      handleMoveToErrorPage('server-error');
    }

    // NOTE: Move the user to session expired page in case of the error response with "IdToken expired." message
    if (error.response) {
      const errorObject = error.response.data?.errors;
      if (errorObject.includes('IdToken expired.')) {
        handleResetLocalStorage();
        handleMoveToErrorPage('session-expired');
      }
    }
    return Promise.reject(error);
  }
);

/** お知らせ一覧取得API */
export const getNotifications = () =>
  noticeApi
    .request({
      url: '/users/current/notifications',
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data.data.notifications as NotificationListItemType[]);

/** お知らせ一括既読API */
export const readNotifications = (ids: number[]) =>
  noticeApi
    .request({
      url: '/users/current/notifications/reads',
      method: 'POST',
      data: { notification_ids: ids },
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data as ResponseType);

/** お知らせ詳細取得API */
export const getNotificationDetail = (id: number) =>
  noticeApi
    .request({
      url: `/users/current/notifications/${id}`,
      method: 'GET',
      headers: getRequestHeader({ hasIdToken: true })
    })
    .then((res) => res.data.data as NotificationDetailType);
