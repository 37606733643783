import { getNotifications, readNotifications } from 'features/notification/api';
import { NotificationListItemType } from 'features/notification/types/NotificationType';
import { unreadNotificationAtom } from 'libs/recoil/atom';
import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

export const useNotificationList = () => {
  const setUnreadNotification = useSetRecoilState(unreadNotificationAtom);
  const [currentStoreId, setCurrentStoreId] = useState<number | null>(null);
  const [allNotificationList, setAllNotificationList] = useState<
    NotificationListItemType[]
  >([]);
  const [displayedNotificationList, setDisplayedNotificationList] = useState<
    NotificationListItemType[]
  >([]);
  const [isDone, setIsDone] = useState(false);
  const [unreadIds, setUnreadIds] = useState<number[]>([]);

  // 一覧取得
  const getNotificationList = useCallback(
    () =>
      getNotifications().then((res) => {
        // 全お知らせ
        setAllNotificationList(res);
        const currUnreadIds = res
          .filter((item) => !item.read)
          .map((item) => item.id);
        // 未読お知らせのグローバル状態を更新
        setUnreadNotification({ hasUnread: !!currUnreadIds.length });
        setIsDone(true);
      }),
    [setUnreadNotification]
  );

  // 既読する
  const readNotification = useCallback(
    (ids: number[]) => {
      setIsDone(false);

      // 未読お知らせのグローバル状態を更新するため、再取得する
      readNotifications(ids).then(() => getNotificationList());
    },
    [getNotificationList]
  );

  // 店舗で絞り込んだお知らせ
  const filterByStore = useCallback(
    (currentStoreId: number) =>
      allNotificationList.filter(
        (item) => !!item.stores?.some((store) => store.id === currentStoreId)
      ),
    [allNotificationList]
  );

  useEffect(() => {
    getNotificationList();
  }, [getNotificationList]);

  useEffect(() => {
    // 店舗IDが指定されているかどうかによって、表示用のお知らせリストを更新
    let list: NotificationListItemType[];
    if (currentStoreId) {
      list = filterByStore(currentStoreId);
    } else {
      list = allNotificationList;
    }
    setDisplayedNotificationList(list);

    // 一覧に表示されている未読お知らせIDs
    const unreadIds = list.filter((item) => !item.read).map((item) => item.id);
    setUnreadIds(unreadIds);
  }, [allNotificationList, currentStoreId, filterByStore]);

  return {
    displayedNotificationList,
    unreadIds,
    setCurrentStoreId,
    filterByStore,
    readNotification,
    isDone
  };
};
