import { Button, Heading, Stack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import CuminMemberProfileList from 'features/membership/components/cumin/CuminMemberProfileList';
import useCardIssueConfirm from 'features/membership/hooks/cumin/useCardIssueConfirm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  cuminMemberFormVisibleFieldsState,
  CuminPagePageTemplateOptionKey,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * カード番号付番/確認
 */
const CuminCardIssueConfirm: FC = () => {
  const { state } = useLocation();
  // TODO: dataを整形してAPI連携する
  const cuminMemberFormVisibleFields = useRecoilValue(
    cuminMemberFormVisibleFieldsState
  );
  const { isMutating, onIssueButtonClick } = useCardIssueConfirm(
    state?.formData
  );
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.CUMIN));

  if (!state?.formData || !Object.keys(state).length) {
    return <Navigate to="/membership/cumin/issue" />;
  }

  return (
    <MainLayout header>
      <Stack pt="3rem" px="1rem" h="100%" justifyContent="space-between">
        <Stack spacing="1.5rem">
          {/* タイトル */}
          <Heading fontSize="xl" fontWeight="700">
            入力内容を確認してください
          </Heading>

          <CuminMemberProfileList
            visibleFields={cuminMemberFormVisibleFields}
            memberProfile={state.formData}
            showEntryStoreCodeField
            occupationOptions={
              templateOptions?.memberFormOccupationObjectListOption?.object_list
            }
          />
        </Stack>

        <Stack gap="1rem" pb="1rem" pt="1rem">
          <Button
            isLoading={isMutating}
            variant="primary-fullwidth-rounded"
            onClick={onIssueButtonClick}
          >
            登録する
          </Button>
          <Button
            isLoading={isMutating}
            variant="primary-outline-fullwidth-rounded"
            as={Link}
            to="/membership/cumin/issue"
            state={state}
          >
            修正する
          </Button>
        </Stack>
      </Stack>
    </MainLayout>
  );
};
export default CuminCardIssueConfirm;
