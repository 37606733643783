import dispatchToast from 'components/modules/Toast';
import { getMemberCardPoints } from 'features/membership/api';
import { PLUGIN_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import { EARTH_FEATURE_CODES } from 'features/membership/libs/const/earthFeatureCodes';
import { membershipPluginFeatureState } from 'features/membership/libs/recoil/selector';
import { API_RESPONSE_STATUS } from 'libs/constants';
import _ from 'lodash';
import { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useRecoilValue } from 'recoil';

/**
 * ポイント残高取得APIを実行に関するhooks
 * @param cardNumber 会員番号
 * @param onUnavailableTimeError API実行が503エラーした際に実行する関数
 */
const useCardPoints = (
  cardNumber: string,
  onUnavailableTimeError?: VoidFunction
) => {
  // 時間外エラーのみを保存する
  const [serviceTime, setServiceTime] = useState<
    | {
        available_hour?: string;
        unavailable_hour?: string;
      }
    | undefined
  >();
  const isPointBalanceAvailable = useRecoilValue(
    membershipPluginFeatureState
  ).earth?.includes(_.camelCase(EARTH_FEATURE_CODES.GET_POINT_BALANCE));

  // ポイント残高取得APIを叩く
  const [{ loading: isMutating, value }, executeApi] = useAsyncFn(async () => {
    if (!cardNumber || !isPointBalanceAvailable) return;
    const res = await getMemberCardPoints(PLUGIN_ALIAS_CODE.EARTH, cardNumber);
    // 保存しているサービス時間を一度削除
    setServiceTime(undefined);

    if (res.status === API_RESPONSE_STATUS.SUCCEEDED) {
      // 正常系の場合
      return res.data;
    }
    // http statusが503の場合かつサービス時間外
    if (
      res.httpStatus === 503 &&
      res?.error_fields &&
      Object.keys(res.error_fields).length
    ) {
      setServiceTime({
        // サービス時間
        available_hour: res.error_fields?.available_hour ?? '',
        // サービス時間外
        unavailable_hour: res.error_fields?.unavailable_hour ?? ''
      });
      onUnavailableTimeError?.();
      return;
    }

    // その他エラー、http statusが503の場合も(ネガカード)
    dispatchToast({
      id: 'membership-member-api-error',
      toastText: res.errors.join('\n')
    });
  }, [cardNumber, isPointBalanceAvailable]);

  return {
    // ポイントや有効期限などの時間のオブジェクト
    data: value,
    // サービス時間外の時間とサービス時間のオブジェクト
    serviceTime,
    isMutating,
    // API実行
    executeApi
  };
};

export default useCardPoints;
