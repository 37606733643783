import { Heading, Skeleton, Stack } from '@chakra-ui/react';
import liff from '@line/liff';
import MainLayout from 'components/layouts/MainLayout';
import dispatchToast from 'components/modules/Toast';
import { getLinks } from 'features/menu/api';
import MenuButton from 'features/menu/components/MenuButton';
import MenuLink from 'features/menu/components/MenuLink';
import { API_RESPONSE_STATUS } from 'libs/constants';
import { tenantConfigurationAtom } from 'libs/recoil/atom';
import { activeFeatureCodesState } from 'libs/recoil/selector';
import { FC } from 'react';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { useRecoilValue } from 'recoil';

/** メニュー */
const Menu: FC = () => {
  const activeFeatureCodes = useRecoilValue(activeFeatureCodesState);
  const tenantConfiguration = useRecoilValue(tenantConfigurationAtom);
  const isFeatureActive = activeFeatureCodes.includes('link');

  const [{ value: links, loading: isMutating }, executeApi] =
    useAsyncFn(async () => {
      const res = await getLinks();

      if (res.status === API_RESPONSE_STATUS.SUCCEEDED) return res.data.links;

      dispatchToast({
        id: 'links-api-error',
        toastText: 'リンクが取得できません'
      });
      return undefined;
    }, []);

  useEffectOnce(() => {
    if (isFeatureActive) executeApi();
  });

  return (
    <MainLayout header footer>
      <Stack spacing="1rem" pt="3rem">
        <Heading size="3xl" pl="0.875rem">
          メニュー
        </Heading>
        <Skeleton isLoaded={!isMutating}>
          <Stack spacing="0">
            {links?.map((e, i) => (
              <MenuLink key={i} to={e.url} buttonText={e.label} index={i} />
            ))}
            <MenuButton
              borderTopWidth={links?.length === 0 ? '0.015rem' : 0} // 一番上に表示する場合は線を表示
              onClick={() => {
                liff.createShortcutOnHomeScreen({
                  url: tenantConfiguration.liff_url
                });
              }}
            >
              ホーム画面追加
            </MenuButton>
          </Stack>
        </Skeleton>
      </Stack>
    </MainLayout>
  );
};

export default Menu;
