import { Stack, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

type LabeledValueProps = {
  label: string;
  value: string | ReactNode;
};

const LabeledValue: FC<LabeledValueProps> = ({ label, value }) => {
  return (
    <Stack spacing=".7rem">
      <Text fontSize="md" fontWeight="500" color="dark.400" mb={0}>
        {label}
      </Text>
      <Text fontSize="md" fontWeight="500" pl="1rem">
        {value}
      </Text>
    </Stack>
  );
};

export default LabeledValue;
