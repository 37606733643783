import { Button, Skeleton, Stack, Text, VStack } from '@chakra-ui/react';
import MainLayout from 'components/layouts/MainLayout';
import LabeledValue from 'components/modules/labeledValue/LabeledValue';
import dayjs from 'dayjs';
import CuminMemberProfileForm from 'features/membership/components/cumin/CuminMemberProfileForm';
import { useMembershipProfileResponse } from 'features/membership/hooks/cumin/useMembershipProfileResponse';
import useProfileEditForm from 'features/membership/hooks/cumin/useProfileEditForm';
import { TEMPLATE_ALIAS_CODE } from 'features/membership/libs/const/aliasCode';
import {
  cuminMemberFormRequiredFieldsState,
  cuminMemberFormVisibleFieldsState,
  CuminPagePageTemplateOptionKey,
  membershipPluginPageTemplateOptionState
} from 'features/membership/libs/recoil/selector';
import { PageTemplateOption } from 'features/membership/types';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/**
 * 会員情報の変更
 * (FYI: 確認画面がないため、付番と違う作りにする必要ある)
 */
const CuminProfileEdit: FC = () => {
  const { methods, formSubmitHandler, isMutating } = useProfileEditForm();

  const { membershipProfileResponse, isMutating: isMembershipProfileMutating } =
    useMembershipProfileResponse();

  const cuminMemberFormVisibleFields = useRecoilValue(
    cuminMemberFormVisibleFieldsState
  );
  const cuminMemberFormRequiredFields = useRecoilValue(
    cuminMemberFormRequiredFieldsState
  );
  const templateOptions = useRecoilValue<
    Record<CuminPagePageTemplateOptionKey, PageTemplateOption> | undefined
  >(membershipPluginPageTemplateOptionState(TEMPLATE_ALIAS_CODE.CUMIN));

  return (
    <MainLayout header>
      <Stack px="1rem" pt="3rem" pb="0.75rem" h="100%">
        <VStack gap="4" alignItems="start" w="100%" pb="0.75rem">
          <Text fontSize="xl" fontWeight="700" m="0">
            会員情報を修正してください
          </Text>
          <Skeleton isLoaded={!isMembershipProfileMutating} w="100%">
            <FormProvider {...methods}>
              <Stack
                as="form"
                onSubmit={formSubmitHandler}
                gap="1rem"
                alignItems="start"
                w="100%"
              >
                <LabeledValue
                  label="カード番号"
                  value={membershipProfileResponse?.card_number.replace(
                    /\w{4}(?=.)/g,
                    '$& '
                  )}
                />
                <CuminMemberProfileForm
                  templateOptions={templateOptions}
                  visibleFields={cuminMemberFormVisibleFields}
                  requiredFields={cuminMemberFormRequiredFields}
                  birthdayTextForDisplay={
                    membershipProfileResponse?.birthday
                      ? dayjs(membershipProfileResponse.birthday).format(
                          'YYYY[年]M[月]D[日]'
                        )
                      : ''
                  }
                  isBirthdayEditable={false}
                />
                {/* 「保存する」ボタン */}
                <Button
                  type="submit"
                  variant="primary-fullwidth-rounded"
                  isDisabled={!methods.formState.isValid}
                  isLoading={isMutating}
                >
                  保存する
                </Button>
              </Stack>
            </FormProvider>
          </Skeleton>

          {/* 「戻る」ボタン */}
          <Button
            as={Link}
            to={`/membership/${TEMPLATE_ALIAS_CODE.CUMIN}/profile`}
            variant="primary-outline-fullwidth-rounded"
            isLoading={isMutating}
          >
            戻る
          </Button>
        </VStack>
      </Stack>
    </MainLayout>
  );
};

export default CuminProfileEdit;
